import Link from "next/link";

export const Logo = () => {
  return (
    <Link href={"/"}>
      <svg
        className={"app-logo"}
        width="835"
        height="500"
        viewBox="0 0 835 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1371_14373)">
          <path
            d="M2.30758 50.186C149.855 50.7915 297.402 51.397 445.141 52.0032C445.141 122.692 445.141 192.947 445.141 263.68C446.677 263.791 448.096 263.979 449.516 263.984C502.99 264.186 556.464 264.369 609.939 264.558C677.074 264.796 744.21 265.037 811.346 265.277C818.841 265.304 826.336 265.336 834.326 265.861C834.905 272.586 834.989 278.814 835.045 285.507C835.013 306.907 834.984 327.841 835.011 348.775C835.042 373.526 835.12 398.278 835.108 423.491C835.026 442.973 834.995 461.992 835.047 481.011C835.051 482.342 835.668 483.671 836 485C836 490.329 836 495.659 836 500.994C645.312 501 454.625 501 263.174 500.704C261.438 500.276 260.466 500.027 259.494 500.027C236.313 500.019 213.131 500.038 189.487 500.037C180.662 500.049 172.301 500.074 163.475 500.078C146.664 500.067 130.318 500.077 113.507 500.05C105.01 500.059 96.9778 500.107 88.4825 500.11C71.9965 500.106 55.9738 500.145 39.4889 500.126C26.3512 500.045 13.6756 500.022 1 500C1 499.556 1 499.111 1.31872 498.06C1.71845 482.289 1.79947 467.125 1.92022 451.496C1.92505 430.675 1.89014 410.318 1.90648 389.497C1.92248 368.68 1.88721 348.325 1.90701 327.507C1.91466 307.018 1.86725 286.992 1.88474 266.505C1.90898 245.686 1.8683 225.33 1.89331 204.51C1.91604 183.693 1.87309 163.338 1.9009 142.521C1.98252 123.246 1.95987 104.435 2.01645 85.623C2.05199 73.8105 2.20647 61.9983 2.30758 50.186ZM761.501 284.717C514.789 283.711 268.076 282.705 21.1637 281.698C21.1637 348.548 21.1637 414.578 21.1637 480.779C286.244 481.797 550.918 482.814 815.627 483.831C815.627 417.237 815.627 351.312 815.627 284.718C797.744 284.718 780.123 284.718 761.501 284.717ZM250.5 70.7116C174.264 70.7116 98.0279 70.7116 21.438 70.7116C21.438 134.243 21.438 197.982 21.438 262.368C156.208 262.883 290.857 263.397 425.628 263.912C425.628 199.393 425.628 135.504 425.628 70.7115C367.387 70.7115 309.444 70.7115 250.5 70.7116Z"
            fill="#371AEC"
          />
          <path
            d="M338.531 1C339 6.43676 339 11.8735 339 17.6023C311.04 17.6023 283.873 17.6023 255.959 17.6023C255.959 12.4132 255.959 7.17429 255.98 1.46769C283.354 1 310.708 1 338.531 1Z"
            fill="#371AEC"
          />
          <path
            d="M164.531 1C165 6.44287 165 11.8857 165 17.6162C137.834 17.6162 110.76 17.6162 82.9338 17.6162C82.9338 12.4308 82.9338 7.18251 82.9669 1.4671C110.021 1 137.042 1 164.531 1Z"
            fill="#371AEC"
          />
          <path
            d="M496.757 231.36C495.891 225.082 495.454 218.901 494.992 212.364C523.035 212.364 550.254 212.364 577.775 212.364C577.775 218.616 577.775 224.83 577.775 231.456C550.776 231.456 523.981 231.456 496.757 231.36Z"
            fill="#371AEC"
          />
          <path
            d="M735 231.417C713.891 231.417 693.282 231.417 672.315 231.417C672.315 224.962 672.315 218.761 672.315 212.303C699.447 212.303 726.478 212.303 753.901 212.303C753.901 218.492 753.901 224.667 753.901 231.417C747.696 231.417 741.598 231.417 735 231.417Z"
            fill="#371AEC"
          />
          <path
            d="M163.747 426.744C156.535 433.484 147.812 436.163 138.784 436.406C119.02 436.938 99.2319 436.571 79.2 436.571C79.2 402.424 79.2 368.368 79.2 333.337C99.5227 333.883 119.61 334.236 139.675 335.119C143.659 335.294 147.803 337.006 151.447 338.852C168.08 347.277 170.251 368.918 155.845 380.867C154.966 381.597 154.124 382.373 153.21 383.176C171.939 393.692 176.311 411.538 163.747 426.744ZM146.138 417.752C151.329 413.974 151.944 408.597 150.715 402.994C149.36 396.817 144.43 393.741 138.758 393.281C127.86 392.396 116.888 392.404 105.946 392.077C103.375 392 100.801 392.065 98.4468 392.065C98.4468 402.439 98.4468 411.991 98.4468 421.627C110.273 421.627 121.732 421.926 133.16 421.469C137.316 421.303 141.399 419.334 146.138 417.752ZM122.461 377.19C126.587 376.831 130.805 376.898 134.817 376.021C141.986 374.455 145.87 369.631 145.904 363.224C145.939 356.646 141.856 350.989 134.507 350.238C122.547 349.016 110.464 349.004 98.3489 348.477C98.3489 358.642 98.3489 367.706 98.3489 377.19C106.238 377.19 113.855 377.19 122.461 377.19Z"
            fill="#371AEC"
          />
          <path
            d="M216.015 361C216.015 351.859 216.015 343.218 216.015 333.857C218.53 333.857 220.782 333.852 223.034 333.858C236.192 333.89 249.363 333.588 262.506 334.056C275.179 334.507 286.796 338.113 295.238 348.471C306.125 361.828 306.268 390.732 283.638 401.546C282.766 401.962 281.921 402.435 280.614 403.116C288.331 414.217 295.87 425.063 304.097 436.898C296.901 436.898 290.686 437.036 284.489 436.767C283.403 436.72 282.18 435.112 281.377 433.974C275.815 426.089 270.281 418.182 264.89 410.18C263.264 407.765 261.52 406.592 258.43 406.696C250.819 406.95 243.193 406.779 235.089 406.779C235.089 416.984 235.089 426.724 235.089 436.69C228.665 436.69 222.729 436.69 216.015 436.69C216.015 411.633 216.015 386.566 216.015 361ZM282.288 365.462C281.021 358.523 276.616 354.179 270.218 352.122C258.749 348.435 246.963 350.689 235.343 350.035C235.343 364.141 235.343 377.378 235.343 390.74C244.723 390.74 253.895 391.428 262.924 390.584C277.651 389.206 284.476 380.203 282.288 365.462Z"
            fill="#371AEC"
          />
          <path
            d="M613.818 395.166C624.986 408.989 635.917 422.548 647.506 436.923C639.885 436.923 633.292 437.1 626.722 436.786C625.254 436.716 623.584 435.163 622.489 433.881C611.243 420.725 600.097 407.483 588.882 394.215C583.498 399.509 578.247 404.523 573.225 409.756C572.37 410.646 572.232 412.474 572.215 413.874C572.121 421.352 572.17 428.832 572.17 436.619C565.796 436.619 559.734 436.619 553.382 436.619C553.382 402.546 553.382 368.62 553.382 334.309C559.38 334.309 565.435 334.309 572.038 334.309C572.038 351.233 572.038 368.261 572.038 386.27C577.455 380.886 582.182 376.328 586.751 371.618C597.77 360.26 608.788 348.901 619.635 337.38C622.145 334.714 624.738 333.647 628.354 333.852C633.426 334.139 638.526 333.925 644.763 333.925C629.909 349.748 615.851 364.723 601.581 379.923C605.705 385.071 609.643 389.986 613.818 395.166Z"
            fill="#371AEC"
          />
          <path
            d="M746.885 387.07C761.785 398.971 760.247 421.074 744.121 431.327C726.301 442.657 691.585 440.016 674.939 425.822C677.035 421.143 679.179 416.358 681.487 411.207C691.485 418.134 702.161 422.164 713.938 422.237C719.018 422.269 724.24 421.512 729.155 420.203C734.992 418.648 737.927 414.499 738.152 409.551C738.415 403.789 735.006 400.352 730.239 398.665C722.896 396.066 715.281 394.241 707.801 392.019C703.664 390.79 699.473 389.664 695.472 388.073C683.026 383.125 677.142 375.117 677.063 363.462C676.983 351.426 683.469 341.363 695.228 336.728C714.904 328.973 733.968 331.468 753.048 341.083C750.953 346.256 748.998 351.085 747.367 355.113C738.784 352.807 730.797 349.776 722.561 348.749C716.699 348.018 710.376 349.473 704.492 350.907C699.401 352.146 696.476 356.295 696.18 361.622C695.879 367.037 699.251 370.559 703.87 372.166C711.539 374.833 719.552 376.504 727.249 379.103C733.819 381.321 740.148 384.255 746.885 387.07Z"
            fill="#371AEC"
          />
          <path
            d="M483.926 351.83C461.591 344.253 439.969 354.586 434.447 375.175C430.19 391.043 436.386 407.979 449.352 415.92C463.444 424.552 482.241 423.03 494.469 412.263C495.45 411.399 496.406 410.506 497.918 409.133C501.627 413.344 505.236 417.442 508.941 421.649C500.236 434.991 474.212 442.246 453.484 436.637C430.902 430.526 415.414 412.42 414.024 390.506C412.437 365.496 424.628 345.057 446.155 336.637C469.349 327.565 495.742 332.87 509.796 349.708C505.865 353.416 501.912 357.144 498.135 360.707C493.409 357.719 488.852 354.837 483.926 351.83Z"
            fill="#371AEC"
          />
          <path
            d="M367.999 373C367.999 394.435 367.999 415.369 367.999 436.614C361.647 436.614 355.595 436.614 349.244 436.614C349.244 402.549 349.244 368.542 349.244 334.233C355.318 334.233 361.372 334.233 367.999 334.233C367.999 347.002 367.999 359.751 367.999 373Z"
            fill="#371AEC"
          />
          <path
            d="M270.067 130.022C270.067 126.037 270.067 122.547 270.067 118.3C275.022 118.3 279.795 118.091 284.527 118.434C285.845 118.53 287.279 120.063 288.262 121.262C305.252 141.976 322.176 162.743 339.128 183.488C340.029 184.591 341.052 185.594 342.79 187.482C342.79 163.875 342.79 141.352 342.79 118.557C349.213 118.557 355.137 118.557 361.453 118.557C361.453 152.647 361.453 186.654 361.453 221.158C356.713 221.158 352.073 221.405 347.486 221.016C346.007 220.89 344.381 219.327 343.31 218.022C326.522 197.571 309.816 177.051 293.078 156.558C292.086 155.344 290.979 154.224 289.19 152.248C289.19 175.742 289.19 198.121 289.19 220.833C282.74 220.833 276.689 220.833 270.067 220.833C270.067 190.697 270.067 160.607 270.067 130.022Z"
            fill="#371AEC"
          />
          <path
            d="M140.921 165.833C143.378 165.833 145.345 165.833 147.606 165.833C147.606 171.297 147.606 176.361 147.606 181.876C131.221 181.876 114.978 181.876 98.255 181.876C98.255 195.043 98.255 207.778 98.255 220.837C91.7147 220.837 85.6524 220.837 79.2646 220.837C79.2646 186.857 79.2646 152.829 79.2646 118.542C104.152 118.542 128.876 118.542 153.86 118.542C153.86 123.715 153.86 128.644 153.86 134.189C135.502 134.189 117.136 134.189 98.4257 134.189C98.4257 144.983 98.4257 155.17 98.4257 165.833C112.539 165.833 126.485 165.833 140.921 165.833Z"
            fill="#371AEC"
          />
          <path
            d="M217.17 180C217.17 193.816 217.17 207.131 217.17 220.808C210.867 220.808 204.805 220.808 198.416 220.808C198.416 186.899 198.416 152.974 198.416 118.664C204.395 118.664 210.453 118.664 217.17 118.664C217.17 138.971 217.17 159.235 217.17 180Z"
            fill="#371AEC"
          />
        </g>
        <defs>
          <clipPath id="clip0_1371_14373">
            <rect width="835" height="500" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Link>
  );
};
