import useTranslation from "next-translate/useTranslation";
import { Logo } from "../logo/Logo";

export const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  return (
    <footer className={"text-light-25 typo-sm"}>
      <div className={"bg-dark-75 lg:px-62 px-4 pt-16 pb-32"}>
        <Logo />
        <p className={"pt-4 lg:w-7/12"}>{t("footer")}</p>
      </div>
      <div className={"bg-dark-50 lg:px-62 flex gap-12 px-4 py-6"}>
        <span>©{currentYear} Finbricks s.r.o.</span>
        <button role={"button"} data-cc="c-settings" className={"underline"}>
          {t("cookies")}
        </button>
      </div>
    </footer>
  );
};
