import { ReactNode } from "react";
import { Footer, Header } from "../../components";

type PageLayoutProps = {
  children?: ReactNode;
};

export const PageLayout = ({ children }: PageLayoutProps) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};
