import React, { ReactNode } from "react";

export type Language = "cs" | "en";

export type LanguageSwitchProps = {
  selected: Language;
  onSelect: (lng: Language) => void;
};

export const LanguageSwitch = ({ selected, onSelect }: LanguageSwitchProps) => {
  const languageToFlagMapper: Record<Language, ReactNode> = {
    en: <FlagCZ />,
    cs: <FlagEN />,
  };
  const ariaLabel: Record<Language, string> = {
    en: "Přepnout jazyk do češtiny",
    cs: "Change language to english",
  };
  const handleClick = () => {
    return onSelect(selected === "cs" ? "en" : "cs");
  };

  return (
    <button className={"ui-language-switch"} onClick={handleClick} aria-label={ariaLabel[selected]}>
      {languageToFlagMapper[selected]}
    </button>
  );
};

const FlagCZ = () => {
  return (
    <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <clipPath id="a">
        <rect height="24" rx="12" width="24" />
      </clipPath>
      <mask id="b" height="24" maskUnits="userSpaceOnUse" width="32" x="-4" y="0">
        <path d="m-4 0h32v24h-32z" fill="#fff" />
      </mask>
      <mask id="c" height="24" maskUnits="userSpaceOnUse" width="32" x="-4" y="0">
        <path clipRule="evenodd" d="m-4 0v24h32v-24z" fill="#fff" fillRule="evenodd" />
      </mask>
      <mask height="24" maskUnits="userSpaceOnUse" width="18" x="-4" y="0">
        <path clipRule="evenodd" d="m-4 0v24l18-12z" fill="#fff" fillRule="evenodd" />
      </mask>
      <g clipPath="url(#a)">
        <g mask="url(#b)">
          <path clipRule="evenodd" d="m-4 0v24h32v-24z" fill="#dc0040" fillRule="evenodd" />
          <g mask="url(#c)">
            <path clipRule="evenodd" d="m-4-2v14h32v-14z" fill="#fdfdfd" fillRule="evenodd" />
          </g>
          <path clipRule="evenodd" d="m-4 0v24l18-12z" fill="#3152c5" fillRule="evenodd" />
        </g>
      </g>
    </svg>
  );
};

const FlagEN = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1849_23591)">
        <mask
          id="mask0_1849_23591"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="-4"
          y="0"
          width="32"
          height="24"
        >
          <rect x="-4" width="32" height="24" fill="white" />
        </mask>
        <g mask="url(#mask0_1849_23591)">
          <path fillRule="evenodd" clipRule="evenodd" d="M-4 0V24H28V0H-4Z" fill="#3152C5" />
          <mask
            id="mask1_1849_23591"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="-4"
            y="0"
            width="32"
            height="24"
          >
            <path fillRule="evenodd" clipRule="evenodd" d="M-4 0V24H28V0H-4Z" fill="white" />
          </mask>
          <g mask="url(#mask1_1849_23591)">
            <mask
              id="mask2_1849_23591"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="-4"
              y="0"
              width="32"
              height="24"
            >
              <rect x="-4" width="32" height="24" fill="white" />
            </mask>
            <g mask="url(#mask2_1849_23591)">
              <path
                d="M-7.56348 22.2854L-0.521787 25.2634L28.1595 3.23781L31.8738 -1.18767L24.3437 -2.18303L12.6453 7.30844L3.22931 13.7035L-7.56348 22.2854Z"
                fill="#FDFDFD"
              />
              <path d="M-6.59961 24.3719L-3.01219 26.1001L30.5398 -1.59884H25.5027L-6.59961 24.3719Z" fill="#DC0040" />
              <path
                d="M31.5635 22.2854L24.5218 25.2634L-4.15945 3.23781L-7.87378 -1.18767L-0.343708 -2.18303L11.3547 7.30844L20.7707 13.7035L31.5635 22.2854Z"
                fill="#FDFDFD"
              />
              <path
                d="M31.3232 23.7829L27.7358 25.5111L13.449 13.6518L9.21329 12.3267L-8.23114 -1.17246H-3.194L14.2406 12.0063L18.8717 13.5952L31.3232 23.7829Z"
                fill="#DC0040"
              />
              <mask id="path-10-inside-1_1849_23591" fill="white">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.7776 -2H8.22203V8H-5.97266V16H8.22203V26H15.7776V16H30.0273V8H15.7776V-2Z"
                />
              </mask>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7776 -2H8.22203V8H-5.97266V16H8.22203V26H15.7776V16H30.0273V8H15.7776V-2Z"
                fill="#DC0040"
              />
              <path
                d="M8.22203 -2V-4H6.22203V-2H8.22203ZM15.7776 -2H17.7776V-4H15.7776V-2ZM8.22203 8V10H10.222V8H8.22203ZM-5.97266 8V6H-7.97266V8H-5.97266ZM-5.97266 16H-7.97266V18H-5.97266V16ZM8.22203 16H10.222V14H8.22203V16ZM8.22203 26H6.22203V28H8.22203V26ZM15.7776 26V28H17.7776V26H15.7776ZM15.7776 16V14H13.7776V16H15.7776ZM30.0273 16V18H32.0273V16H30.0273ZM30.0273 8H32.0273V6H30.0273V8ZM15.7776 8H13.7776V10H15.7776V8ZM8.22203 0H15.7776V-4H8.22203V0ZM10.222 8V-2H6.22203V8H10.222ZM-5.97266 10H8.22203V6H-5.97266V10ZM-3.97266 16V8H-7.97266V16H-3.97266ZM8.22203 14H-5.97266V18H8.22203V14ZM10.222 26V16H6.22203V26H10.222ZM15.7776 24H8.22203V28H15.7776V24ZM13.7776 16V26H17.7776V16H13.7776ZM30.0273 14H15.7776V18H30.0273V14ZM28.0273 8V16H32.0273V8H28.0273ZM15.7776 10H30.0273V6H15.7776V10ZM13.7776 -2V8H17.7776V-2H13.7776Z"
                fill="#FDFDFD"
                mask="url(#path-10-inside-1_1849_23591)"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1849_23591">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
