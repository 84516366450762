import { useEffect } from "react";
import { Language, LanguageSwitch } from "@finbricks/ui/src/LanguageSwitch/LanguageSwitch";
import { useRouter } from "next/router";
import setLanguage from "next-translate/setLanguage";
import useTranslation from "next-translate/useTranslation";
import { Logo } from "../logo/Logo";

// ...

function usePersistLocaleCookie() {
  const { locale } = useRouter();

  useEffect(persistLocaleCookie, [locale]);
  function persistLocaleCookie() {
    const date = new Date();
    const expireMs = 100 * 24 * 60 * 60 * 1000; // 100 days
    date.setTime(date.getTime() + expireMs);
    document.cookie = `NEXT_LOCALE=${locale};expires=${date.toUTCString()};path=/`;
  }
}

export const Header = () => {
  const { lang } = useTranslation();
  usePersistLocaleCookie();
  return (
    <header
      className={"bg-light-0 lg:px-37.5 z-navigation sticky top-0 flex h-16 w-full items-center justify-between px-4"}
    >
      <Logo />
      <LanguageSwitch selected={lang as Language} onSelect={(lang) => setLanguage(lang, false)} />
    </header>
  );
};
